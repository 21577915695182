import { Helmet } from "react-helmet";

import { routes } from "../misc/routes";

import PageWithHeaderCallToAction from "../components/PageWithHeaderCallToAction";
import ContentSection from "../components/ContentSection";
import Div from "../components/Div";

import PlatformGraphic from "../img/platform.svg";

const Integrations = () => {
  return (
    <PageWithHeaderCallToAction
      title="Integrations"
      subtitle=""
      callToAction={{
        title: "Request a Demo",
        subtitle:
          "Let us share our amazing platform with your school district.",
        buttonHref: routes.Sales,
        buttonText: "Get Started",
      }}
    >
      <Helmet titleTemplate="InTouch | %s">
        <title>Accounting Integration</title>
        <meta
          name="description"
          content="Integration is critical for an efficient and streamlined experience."
        />
      </Helmet>

      <Div
        textAlign="center"
        classNames={["text-intouch-green"]}
        marginTop={2}
        marginBottom={5}
        display={6}
      >
        Integration is critical for an efficient and streamlined experience.
      </Div>

      <Div row>
        <Div col>
          <ContentSection title="Platform">
            <Div row marginBottom={3}>
              <Div col={12} colMd={6}>
                <p>
                  The InTouch platform is built upon three cornerstones;
                  Accounting, Point of Sale, and Payments Portal.
                </p>
                <p>
                  InTouch together with 3rd party integrations, remove friction
                  from business processes while providing a convenient experience
                  to students, parents, community, and the district.
                </p>

                <Div marginBottom={4}>
                  <h5 className="text-intouch-blue">
                    <a href={routes.AccountingIntegration} className="h5-link">
                      Accounting
                    </a>
                  </h5>
                  <p>
                    Role-based security per user, controlling who can do what.
                  </p>
                  <p>
                    Streamlined bank reconciliation solves workflow issues
                    such as processing time, accuracy of data, controls and
                    other daily obstacles.
                  </p>
                  <p>
                    Complete audit trail for all general ledger postings – from
                    the receipt to the consolidated ledger posting.
                  </p>
                </Div>

                <Div marginBottom={4}>
                  <h5 className="text-intouch-blue">
                    <a href={routes.PointOfSale} className="h5-link">
                      Point of Sale
                    </a>
                  </h5>
                  <p>
                    Born from our roots in grocery and restaurant environments,
                    and purpose-built for the unique challenges of the school
                    district.
                  </p>
                  <p>
                    Use it anytime, anywhere to accept cash, check, and credit
                    card payments; all with built-in internal controls.
                  </p>
                </Div>

                <h5 className="text-intouch-blue">
                  <a href={routes.PaymentsPortal} className="h5-link">
                    Payments Portal
                  </a>
                </h5>
                <p>Pay for everyone in one transaction</p>
                <p>Pay for everything in one place</p>
                <p>Pay on any device</p>
                <p>
                  Provides real-time access to view fines, fees, and purchase
                  history all in one place.
                </p>
              </Div>

              <Div col={12} colMd={6}>
                <img
                  src={PlatformGraphic}
                  alt="InTouch Platform"
                  style={{ width: "100%" }}
                />
              </Div>
            </Div>

            <Div row>
              <Div col>
                <ContentSection title="Integrations">
                  <Div row>
                    <Div col>
                      <p>
                        InTouch integrates with the most commonly used education
                        industry software products to reduce duplication of
                        effort, increase accuracy and streamline processes.
                      </p>
                    </Div>
                  </Div>
                  <Div row>
                    <Div col={12} colSm={6} colLg={3}>
                      <h5 className="text-intouch-blue">SIS</h5>
                      <ul>
                        <li>Aeries</li>
                        <li>Aspen</li>
                        <li>eSchoolPLUS</li>
                        <li>Infinite Campus</li>
                        <li>JMC</li>
                        <li>PowerSchool</li>
                        <li>Q (Aequitas)</li>
                        <li>Qmlativ</li>
                        <li>Skyward</li>
                        <li>Synergy</li>
                        <li>Tyler SIS</li>
                        <li>Wen-GAGE</li>
                      </ul>
                    </Div>
                    <Div col={12} colSm={6} colLg={3}>
                      <h5 className="text-intouch-blue">Finance / GL</h5>
                      <ul>
                        <li>Alio</li>
                        <li>BusinessPLUS/IFAS</li>
                        <li>eFinance</li>
                        <li>Infinite Visions</li>
                        <li>Munis</li>
                        <li>Qmlativ</li>
                        <li>Software Unlimited</li>
                        <li>Skyward</li>
                        <li>Wen-GAGE</li>
                      </ul>
                    </Div>
                    <Div col={12} colSm={6} colLg={3}>
                      <h5 className="text-intouch-blue">Nutrition</h5>
                      <ul>
                        <li>eTrition</li>
                        <li>Infinite Campus</li>
                        <li>MealMagic</li>
                        <li>MealsPlus (Linq)</li>
                        <li>MealTime</li>
                        <li>PrimeroEdge</li>
                        <li>Qmlativ</li>
                        <li>Skyward</li>
                        <li>Titan School Solutions (Linq)</li>
                        <li>Total K12</li>
                      </ul>
                    </Div>
                    <Div col={12} colSm={6} colLg={3}>
                      <h5 className="text-intouch-blue">
                        Library / Textbook / Assets
                      </h5>
                      <ul>
                        <li>Follett Destiny</li>
                        <li>IncidentIQ</li>
                        <li>Insignia</li>
                        <li>TipWeb</li>
                        <li>Vizor</li>
                      </ul>
                    </Div>
                  </Div>
                </ContentSection>
              </Div>
            </Div>
          </ContentSection>
        </Div>
      </Div>
    </PageWithHeaderCallToAction>
  );
};

export default Integrations;
